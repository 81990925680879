// helper 定义通用的非业务函数降低其它函数的复杂度，业务类函数（不可在其它项目中复用）可以定义至 util.ts
export default {
  // parseInt for an array
  parseIntArray: function (array) {
    var intArray = []
    if (array) {
      array.forEach(function (element, index) {
        intArray[index] = parseInt(element)
      })
      return intArray
    } else { return null }
  },
  // judge if element 'needle' is in an array 'haystack'
  inArray: function (needle, haystack) {
    var length = haystack.length
    for (var i = 0; i < length; i++) {
      if (haystack[i] === needle) { return true }
    }
    return false
  },
  /**
   * Camelize an object
   *
   */
  camelize: function (input) {
    return (function recurse (input) {
      if (isObject(input)) {
        return Object.keys(input).reduce(function (acc, key) {
          var _a
          Object.assign(acc, (_a = {}, _a[toCamel(key)] = recurse(input[key]), _a))
          return acc
        }, {})
      } else if (isArray(input)) {
        return input.map(function (i) { return recurse(i) })
      }
      return input
    })(input)
  }
}
var toCamel = function (str) {
  return str.replace(/([_-][a-z])/gi, function ($1) {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}
var isArray = function (input) {
  return Array.isArray(input)
}
var isObject = function (obj) {
  return (obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function')
}
