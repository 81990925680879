<template>
<div class="programs">
  <h1 class="title grey--text">项目列表</h1>
  <v-container class="my-5">
    <v-layout justify-center class="mb-2">
      <v-flex class="d-flex justify-center">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'upsert-program'})">
          <v-icon>add</v-icon>
          创建项目
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card>
      <v-tabs
        fixed-tabs
        v-model="tab"
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#published">
          已发布
          <v-icon>publish</v-icon>
        </v-tab>

        <v-tab href="#pending">
          审核中
          <v-icon>pending_actions</v-icon>
        </v-tab>

        <v-tab href="#draft">
          草稿
          <v-icon>mode_edit_outline</v-icon>
        </v-tab>

        <v-tab href="#closed">
          已关闭
          <v-icon>disabled_by_default</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="published">
          <v-card flat>
            <v-layout class="flex-column align-center">
              <v-flex v-for="(program, index) in publishedPrograms" v-bind:key="index" xs12 md9 class="ma-1" style="width:550px;">
                <y-program
                  :program="program"
                  v-on:toggle-program-enrollment-status="program.enrolling = !program.enrolling"
                  v-on:toggle-program-visibility="program.visibleToPublic = !program.visibleToPublic"
                  v-on:deactivate-program="reloadPage"
                ></y-program>
              </v-flex>
            </v-layout>
          </v-card>
        </v-tab-item>
        <v-tab-item value="pending">
          <v-card flat>
            <v-layout class="flex-column align-center">
              <v-flex v-for="(program, index) in pendingPrograms" v-bind:key="index" xs12 md9 class="ma-1" style="width:550px;">
                <y-program
                  :program="program"
                  v-on:program-approval-request-withdraw="reloadPage"
                  v-on:acknowledge-program-need-improvement="reloadPage"
                ></y-program>
              </v-flex>
            </v-layout>
          </v-card>
        </v-tab-item>
        <v-tab-item value="draft">
          <v-card flat>
            <v-layout class="flex-column align-center">
              <v-flex v-for="(program, index) in draftPrograms" v-bind:key="index" xs12 md9 class="ma-1" style="width:550px;">
                <y-program
                  :program = program
                  v-on:program-delete="programDelete($event, draftPrograms, index)"
                  v-on:program-approval-request="reloadPage"
                ></y-program>
              </v-flex>
            </v-layout>
          </v-card>
        </v-tab-item>
        <v-tab-item value="closed">
          <v-card flat>
            <v-layout class="flex-column align-center">
              <v-flex v-for="(program, index) in closedPrograms" v-bind:key="index" xs12 md9 class="ma-1" style="width:550px;">
                <y-program
                  :program="program"
                  v-on:activate-program="reloadPage"
                  v-on:program-delete="reloadPage"
                ></y-program>
              </v-flex>
            </v-layout>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import ProgramService from '@/services/ProgramService'
import YProgram from '@/components/YProgram'
import helper from '../lib/helper'

import Vue from 'vue'

export default {
  components: {
    YProgram
  },
  data () {
    return {
      programs: [],
      publishedPrograms: [],
      draftPrograms: [],
      pendingPrograms: [],
      closedPrograms: [],
      tab: null
    }
  },
  methods: {
    async programDelete (program, deleteFromArr, index) {
      console.log('触发项目删除完成事件... program: ')
      console.log(program)
      this.removeProgramCardsFromPage([program.programCommonPropertyId])
    },
    removeProgramCardsFromPage (programCommonPropertyIds) {
      for (const programCommonPropertyId of programCommonPropertyIds) {
        let prgramIndexesToBeRemoved = []
        this.draftPrograms.filter(function (program, index, arr) {
          if (program.programCommonPropertyId === programCommonPropertyId) {
            prgramIndexesToBeRemoved.push(index)
            return true
          }
        })
        prgramIndexesToBeRemoved.sort(function (a, b) { return b - a })
        prgramIndexesToBeRemoved.forEach((programIndexToBeRemoved) => {
          this.draftPrograms.splice(programIndexToBeRemoved, 1)
        })
      }
    },
    programApprovalRequest (event, fromArr, index) {
      console.log(`触发项目提交审核完成事件... `)
      this.$router.go()
    },
    programApprovalRequestWithdraw (event, fromArr, index) {
      console.log(`触发项目撤回审核事件... `)
      this.$router.go()
    },
    reloadPage () {
      this.$store.dispatch('setQueryingState', true)
      this.$router.go()
    },
    moveProgramCardAmongTabs (fromArr, index, toArr) {
      let programToBeMoved = this[fromArr][index]
      this[fromArr].splice(index, 1) // remove element
      this[toArr].unshift(programToBeMoved)
    }
  },
  async mounted () {
    this.$store.dispatch('setQueryingState', true)
    let organizationId
    if (this.$route.query.organizationId) {
      organizationId = this.$route.query.organizationId
      console.log('path query detected: ')
      console.log(this.$route.query.organizationId)
    } else {
      if (this.$store.state.account.organizations.length > 0) {
        organizationId = this.$store.state.account.organizations[0].id
      }
    }
    // get programs
    const res = await ProgramService.getCategorizedPrograms({ organizationId, isPublished: true, isDraft: true, isPending: true, isClosed: true, attatchPaidedStudentsInfo: true, attatchTags: true, attatchPricePackages: false, attatchMedia: true, attatchDetailedMedias: true })
    this.publishedPrograms = helper.camelize(res.data.publishedPrograms)
    this.draftPrograms = helper.camelize(res.data.draftPrograms)
    this.pendingPrograms = helper.camelize(res.data.pendingPrograms)
    this.closedPrograms = helper.camelize(res.data.closedPrograms)
    this.publishedPrograms.forEach(element => {
      Vue.set(element, 'show', false) // Use vue.set to dynamically add properties to object and make it reactive
    })
    this.$store.dispatch('setQueryingState', false)
    console.log(this.programs)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
