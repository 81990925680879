<template>
<div class="program-card mx-5">
  <v-layout align-center justify-center row wrap>
    <v-card style="width:500px;" class="ma-5">
      <v-img
        :src="program.imgUrl"
      >
        <!-- 项目头图附加提示信息 -->
        <div
          v-if="!program.visibleToPublic && [9].includes(program.statusCode)"
          class="fill-height repeating-gradient text-align-vertical-center white--text">
          <v-chip
            color="white lighten-5"
            opacity
            :ripple="false"
            outlined
          >
            <v-icon>visibility_off</v-icon>
            公共不可见
          </v-chip>
        </div>
        <div
          v-if="!program.enrolling && [9].includes(program.statusCode)"
          class="white--text fill-height text-align-vertical-center"
          style="background-color: rgba(0, 0, 0, 0.5);"
        >
          <v-chip
            color="white lighten-5"
            opacity
            :ripple="false"
            outlined
          >
            <v-icon>pause</v-icon>
            暂停招生
          </v-chip>
        </div>
      </v-img>

      <!-- 图下标题信息 -->
      <v-card-title primary-title>
        <div>
          <div class="grey--text text-caption"> <v-icon color="grey">business</v-icon> {{ program.organization }} </div>
          <div class="grey--text text-caption"><span v-if="program.category3">{{ program.category3 }}</span><span v-if="program.category2"> > {{ program.category2 }}</span><span v-if="program.category"> > {{ program.category }}</span></div>
          <div class="title">{{ program.title }} <span class="grey--text" v-if="unpublishedProgramCommonStatusCodes.includes(program.programCommonPropertyStatusCode)">[{{program.programCommonPropertyStatus}}]</span></div>
          <!-- 项目标签 -->
          <div class="text-caption font-weight-black" v-if="program.tutor"> <v-avatar size="36px"><img :src="program.tutorAvartarUrl" :alt="program.tutor"></v-avatar> {{ program.tutor? program.tutor: '[待分配]'}} </div>
          <div v-if="program.tags.length > 0">
            <span v-for="(tag, index) in program.tags" :key="index">
              <v-chip
                class="ma-2"
                color="secondary"
                outlined
              >
                <v-icon left>
                  {{ tag.icon }}
                </v-icon>
                {{tag.name}}
              </v-chip>
            </span>
          </div>
          <!-- 价格信息 -->
          <!-- <div @click="$router.push({
            name: 'program-purchase-packages-upsert',
            params:{
              programInfo: program
            }
            })"
            style="cursor: pointer;"
          >
            <span class="subheader primary--text" v-if="program.packagesForPurchase.length > 0">
              {{ programPurchasePackagesToPriceRangeString(program.packagesForPurchase, true) }}
            </span>
            <span v-else class="grey--text">该项目未设定价格</span>
            <span>
            <v-icon medium>edit_note</v-icon>
            </span>
          </div> -->
          <!-- 付费生员统计 -->
          <div v-if="program.paidStudents">
            <span class="text-caption purpole--text" v-if="program.paidStudents.length > 4"> <v-icon color="grey"> people </v-icon> 付费学员: {{program.paidStudents.length}} </span>
          </div>
        </div>
      </v-card-title>

      <!-- 审核反馈信息 -->
      <v-banner
        v-if="[1].includes(program.programApprovalStatusCode)"
        color="orange lighten-5"
        class="orange--text"
      >
        <v-icon slot="icon" color="orange">feedback</v-icon>
        {{program.programApprovalImprovementComment}}
        <template v-slot:actions>
          <v-dialog
            v-if="[4].includes(program.programCommonPropertyStatusCode)"
            v-model="dialogAcknowledgeNeedImprovement"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="orange"
                v-bind="attrs"
                v-on="on"
                class="orange--text font-weight-bold"
              >
                下一步
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">
                继续修改
              </v-card-title>

              <v-card-text>
                项目将被移至草稿，请根据反馈改进后再次提交。
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  @click="dialogAcknowledgeNeedImprovement = false"
                >
                  取消
                </v-btn>
                <v-btn
                  color="error"
                  @click="acknowledgeNeedImprovement"
                >
                  继续
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-banner>
      <!-- 便捷操作按钮 -->
      <!-- 状态参考
        ProgramStatusLookup - CLOSED: 0, RECYCLED: 1, DRAFT: 2, PUBLISHED: 9
        ProgramCommonPropertyStatusLookup - 3:DRAFT, 4:PENDING, 5:APPROVED
        ProgramApprovalStatusLookup - NEED_IMPROVEMENT: 1, PENDING: 4, APPROVED: 5, WITHDRAW: 6
       -->
      <v-card-actions flex>
        <!-- 编辑 [program_common_property: DRAFT] -->
        <v-btn
          v-if="[ProgramCommonPropertyStatusLookup.DRAFT].includes(program.programCommonPropertyStatusCode)"
          icon
          @click="$router.push({
            name: 'upsert-program',
            params:{
              programInfo: program
            }
          })"
          >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          v-if="[ProgramStatusLookup.PUBLISHED].includes(program.statusCode)"
          icon
          @click="$router.push({
            name: 'lesson-scheduler',
            query: {
              programId: program.id
            }
          })"
          >
          <v-icon>calendar_today</v-icon>
        </v-btn>
        <!-- 编辑 [program_common_property: PUBLISHED] -->
        <v-dialog
            v-if="[ProgramCommonPropertyStatusLookup.APPROVED].includes(program.programCommonPropertyStatusCode)"
            v-model="dialogEditProgram"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                >
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">
                编辑已发布项目
              </v-card-title>

              <v-card-text>
                编辑该项目，审核通过并发布后将代替已有项目信息，项目招生信息、教学信息等不受影响。
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  @click="dialogEditProgram = false"
                >
                  取消
                </v-btn>
                <v-btn
                  color="primary"
                  @click="$router.push({
                    name: 'upsert-program',
                    params:{
                      programInfo: program
                    }
                  })"
                >
                  继续编辑
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <!-- 编辑 [program_common_property: APPROVED] 通过审核的项目属性：复制到草稿(保证始终有一个可使用的属性) -->
        <!-- <v-btn
          v-if="[5].includes(program.programCommonPropertyStatusCode)"
          text
          @click="$router.push({
          name: 'upsert-program',
          params:{
            programInfo: program
          }
        })">
          <v-icon>edit</v-icon> 编辑
        </v-btn> -->
        <!-- 复制为新项目 [program_common_property: APPROVED] 通过审核的项目属性：复制为新项目(去除传入的programId信息) -->
        <v-dialog
            v-if="[5].includes(program.programCommonPropertyStatusCode)"
            v-model="dialogDuplicateProgram"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="[5].includes(program.programCommonPropertyStatusCode)"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>content_copy</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">
                复制为新项目
              </v-card-title>

              <v-card-text>
                复制该项目为一个新的项目，并在此基础上进行修改与发布。
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  @click="dialogDuplicateProgram = false"
                >
                  取消
                </v-btn>
                <v-btn
                  color="primary"
                  @click="duplicateAsNewProgram(program)">
                  复制为新项目
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <!-- 招生状态切换 [program_common_property: APPROVED] -->
        <v-btn
          v-if="[5].includes(program.programCommonPropertyStatusCode) && [9].includes(program.statusCode)"
          icon
          :class="`${program.enrolling? 'green--text': 'red--text'}`"
          @click="toggleProgramEnrollmentStatus"
        >
          <v-icon>{{ program.enrolling? 'pause': 'play_arrow' }}</v-icon>{{ program.enrolling? '': '' }}
        </v-btn>
        <!-- 隐藏  [program_common_property: APPROVED && program: PUBLISHED] -->
        <v-btn
          v-if="program.statusCode === 9 && [5].includes(program.programCommonPropertyStatusCode)"
          icon
          :class="`${program.visibleToPublic? 'green--text': 'red--text'}`"
          @click="toggleProgramVisibility"
        >
          <v-icon>{{ program.visibleToPublic? 'visibility_off': 'visibility' }}</v-icon> {{ program.visibleToPublic? '': '' }}
        </v-btn>
        <!-- 关闭  [program: PUBLISHED] -->
        <v-btn
          v-if="program.statusCode === 9 && [5].includes(program.programCommonPropertyStatusCode)"
          icon
          @click="deactivateProgram">
          <v-icon>cancel_presentation</v-icon>
        </v-btn>
        <!-- 开放 [program: CLOSED] -->
        <v-btn
          v-if="program.statusCode === 0 && [5].includes(program.programCommonPropertyStatusCode)"
          icon
          @click="activateProgram">
          <v-icon>bolt</v-icon>激活
        </v-btn>
        <!-- 提交审核 [program_common_property: DRAFT]-->
        <!-- <v-dialog
          v-if="[3].includes(program.programCommonPropertyStatusCode)"
          v-model="dialogSubmitForApproval"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon >upload_file</v-icon>提交审核
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">
              提交审核
            </v-card-title>

            <v-card-text>
              提交审核
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                @click="dialogSubmitForApproval = false"
              >
                取消
              </v-btn>
              <v-btn
                color="error"
                @click="approvalRequest"
              >
                提交
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
        <!-- 撤销审核 [program_common_property: PENDING]-->
        <span v-if="[4].includes(program.programCommonPropertyStatusCode)">
          <span class="text-caption">提交时间: {{ programApprovalRequestTime }}</span>
          <v-dialog
            v-model="dialogWithdrawApprovalRequest"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn text
                v-bind="attrs"
                v-on="on"
              >
                <v-icon >cancel</v-icon>撤回
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">
                撤回审核
              </v-card-title>

              <v-card-text>
                撤回审核
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  @click="dialogWithdrawApprovalRequest = false"
                >
                  取消
                </v-btn>
                <v-btn
                  color="error"
                  @click="withdrawApprovalRequest"
                >
                  撤回
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </span>
        <!-- 删除 [program_common_property: DRAFT || program: CLOSED] -->
        <v-dialog
          v-if="[3].includes(program.programCommonPropertyStatusCode) || [0].includes(program.statusCode)"
          v-model="dialogDeleteProgram"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="grey">delete</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">
              删除项目
            </v-card-title>

            <v-card-text>
              将删除该项目草稿副本，删除后不可恢复。不会影响已经发布的项目及其报名信息。
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                @click="dialogDeleteProgram = false"
              >
                取消
              </v-btn>
              <v-btn
                color="error"
                @click="deleteProgram"
              >
                确定
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDetail = !showDetail">
          <v-icon>{{ showDetail ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
        </v-btn>
      </v-card-actions>

      <!-- 被隐藏详情 -->
      <v-slide-y-transition>
        <v-card-text v-show="showDetail" class="grey--text">
          <!-- 快速属性图标 -->
          <span class="text-caption mx-2" v-if="program.entryStudentAgeMonthLowerLimit && program.entryStudentAgeMonthUpperLimit">
            <v-icon> face </v-icon>
            <span class="text-caption">{{ program.entryStudentAgeMonthLowerLimit / 12 }} ~ {{ program.entryStudentAgeMonthUpperLimit / 12 }}岁</span>
          </span>
          <span class="text-caption mx-2">
            <v-icon>watch_later</v-icon> {{ program.sessionModuleQuantity }} X {{ program.moduleLengthMin }} 分钟/次 (总{{program.totalModuleQuantity / program.sessionModuleQuantity}}次)
          </span>
          <span v-if="program.maxStudentNumberPerSession" class="text-caption mx-2">
            <v-icon color="grey"> groups </v-icon>
            <span class="text-caption"> 班容量: {{program.maxStudentNumberPerSession}} 人</span>
          </span>
          <span v-if="program.interimAdmission" class="text-caption mx-2">
            <v-icon color="grey">mediation</v-icon> 可插班
          </span>
          <!-- 项目描述 -->
          <div v-if="program.description">
            <div class="text-subtitle-1 mt-5"><v-icon class="mr-2">assignment</v-icon>描述</div>
            <v-divider class="mb-2"></v-divider>
            {{ program.description }}
          </div>
          <!-- program.objective -->
          <div v-if="program.objectiveTags.length > 0 || program.objective">
            <div class="text-subtitle-1 mt-5"><v-icon class="mr-2">sports_score</v-icon>教学目标</div>
            <v-divider class="md-2"></v-divider>
          </div>
          <div v-if="program.objectiveTags.length > 0">
            <span v-for="(tag, index) in program.objectiveTags" :key="index">
              <v-chip
                class="ma-2"
                color="blue"
                outlined
                small
              >
                <v-icon small left>
                  {{ tag.icon }}
                </v-icon>
                {{tag.name}}
              </v-chip>
            </span>
          </div>
          <div v-if="program.trainingObjective">{{program.trainingObjective}}</div>
          <div v-if="program.trainingApproach">
            <div class="text-subtitle-1 mt-5"><v-icon class="mr-2">directions</v-icon>教学方式</div>
            <v-divider class="md-2"></v-divider>
            <div class="mt-2">{{program.trainingApproach}}</div>
          </div>
          <!-- program.targetStudent -->
          <div v-if="program.targetStudentTags.length > 0 || program.targetStudent">
            <div class="text-subtitle-1 mt-5"><v-icon class="mr-2">person_search</v-icon>目标学员</div>
            <v-divider></v-divider>
          </div>
          <div v-if="program.targetStudentTags.length > 0">
            <span v-for="(tag, index) in program.targetStudentTags" :key="index">
              <v-chip
                class="ma-2"
                color="blue"
                outlined
                small
              >
                <v-icon small left>
                  {{ tag.icon }}
                </v-icon>
                {{tag.name}}
              </v-chip>
            </span>
          </div>
          <div v-if="program.targetStudent">{{program.targetStudent}}</div>
          <!-- program.prerequisite -->
          <div v-if="program.prerequisiteTags.length > 0 || program.prerequisite">
            <div class="text-subtitle-1 mt-5"><v-icon class="mr-2">how_to_reg</v-icon>前置要求</div>
            <v-divider></v-divider>
          </div>
          <div v-if="program.prerequisiteTags.length > 0">
            <span v-for="(tag, index) in program.prerequisiteTags" :key="index">
              <v-chip
                class="ma-2"
                color="blue"
                outlined
                small
              >
                <v-icon small left>
                  {{ tag.icon }}
                </v-icon>
                {{tag.name}}
              </v-chip>
            </span>
          </div>
          <div v-if="program.prerequisite">{{program.prerequisite}}</div>
        </v-card-text>
      </v-slide-y-transition>
    </v-card>
  </v-layout>
</div>
</template>

<script>
import moment from 'moment'
import ProgramService from '@/services/ProgramService'
import { ProgramStatusLookup, ProgramCommonPropertyStatusLookup } from '../enums/index'
export default {
  props: { // make it possible to pass in parameter <panel title="课程模版">
    program: {
      type: Object,
      default: null
    }
  },
  components: {
  },
  data () {
    return {
      showDetail: false,
      unpublishedProgramCommonStatusCodes: [3, 4], // 在非正式发布的项目title处显示[] // const ProgramStatusLookup = { CLOSED: 0, RECYCLED: 1, DRAFT: 2, PUBLISHED: 9 }
      unpublishedProgramStatusCodes: [0, 1, 2], // 暂时无用 // const ProgramCommonPropertyStatusLookup = { DRAFT: 3, PENDING: 4, APPROVED: 5 }
      dialogDeleteProgram: false,
      dialogSubmitForApproval: false,
      dialogWithdrawApprovalRequest: false,
      dialogAcknowledgeNeedImprovement: false,
      dialogDuplicateProgram: false,
      dialogEditProgram: false,
      ProgramStatusLookup: ProgramStatusLookup,
      ProgramCommonPropertyStatusLookup: ProgramCommonPropertyStatusLookup
    }
  },
  computed: {
    programApprovalRequestTime: function () {
      if (this.program) {
        return moment(this.program.programApprovalRequestTimestamp).format('YYYY-MM-DD(dddd) HH:mm')
      } else return null
    }
  },
  async mounted () {
  },
  methods: {
    // async upsertProgram () {
    // },
    async deleteProgram () {
      const programCommonPropertyId = this.program.programCommonPropertyId
      await ProgramService.deletePrograms({ programCommonPropertyIds: [programCommonPropertyId] })
      this.dialogDeleteProgram = false
      this.$emit('program-delete', this.program)
    },
    async approvalRequest () {
      const res = await ProgramService.approvalRequest({ programCommonPropertyId: this.program.programCommonPropertyId })
      this.dialogSubmitForApproval = false
      this.$emit('program-approval-request', res)
      console.log(res)
    },
    async withdrawApprovalRequest () {
      const res = await ProgramService.approvalRequestWithdraw({ programApprovalRequestId: this.program.programApprovalRequestId })
      this.dialogWithdrawApprovalRequest = false
      this.$emit('program-approval-request-withdraw', res)
      console.log(res)
    },
    async toggleProgramVisibility () {
      await ProgramService.upsertProgram({
        id: this.program.id,
        visibleToPublic: !this.program.visibleToPublic
      })
      this.$emit('toggle-program-visibility', !this.program.visibleToPublic)
    },
    async toggleProgramEnrollmentStatus () {
      await ProgramService.upsertProgram({
        id: this.program.id,
        enrolling: !this.program.enrolling
      })
      this.$emit('toggle-program-enrollment-status', !this.program.enrolling)
    },
    async deactivateProgram () {
      await ProgramService.upsertProgram({
        id: this.program.id,
        statusCode: 0
      })
      this.$emit('deactivate-program')
    },
    async activateProgram () {
      await ProgramService.upsertProgram({
        id: this.program.id,
        statusCode: 9,
        enrolling: true,
        visibleToPublic: true
      })
      this.$emit('activate-program')
    },
    async acknowledgeNeedImprovement () {
      await ProgramService.upsertProgram({
        programId: this.program.programId,
        programCommonPropertyId: this.program.programCommonPropertyId,
        programCommonPropertyStatusCode: 3
      })
      this.$emit('acknowledge-program-need-improvement')
    },
    duplicateAsNewProgram (program) {
      if (program.programId) delete program.programId
      if (program.id) delete program.id
      if (program.publishProgramCommonPropertyId) delete program.publishProgramCommonPropertyId
      if (program.editProgramCommonPropertyId) delete program.editProgramCommonPropertyId
      if (program.statusCode) delete program.statusCode
      if (program.enrolling) delete program.enrolling
      if (program.visibleToPublic) delete program.visibleToPublic
      if (program.programSpecificPropertySkillLevelId) delete program.programSpecificPropertySkillLevelId
      if (program.medias) {
        program.medias.forEach((media) => {
          if (media.id) delete media.id
        })
      }
      this.dialogDuplicateProgram = false
      this.$router.push({
        name: 'upsert-program',
        params: {
          programInfo: program
        }
      })
    },
    programPurchasePackagesToPriceRangeString (purchasePackages, onlyCalcuateVisibleToPublicPackages) {
      let priceString = null
      if (purchasePackages) {
        let consideredPurchasePackages = []
        if (onlyCalcuateVisibleToPublicPackages) {
          consideredPurchasePackages = purchasePackages.filter(purchasePackage => purchasePackage.visibleToPublic)
        } else {
          consideredPurchasePackages = purchasePackages
        }
        // sort from low to high
        consideredPurchasePackages.sort(function (a, b) {
          return a.priceFen - b.priceFen
        })
        if (consideredPurchasePackages.length > 0) {
          const minPriceYuan = (consideredPurchasePackages[0].priceFen / 100)
          const maxPriceYuan = (consideredPurchasePackages[consideredPurchasePackages.length - 1].priceFen / 100)
          if (maxPriceYuan !== minPriceYuan) {
            priceString = `￥${minPriceYuan} ~ ${maxPriceYuan}`
          } else {
            priceString = `￥${maxPriceYuan}`
          }
        } else {
          priceString = '价格尚未公开'
        }
      }
      return priceString
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .repeating-gradient {
    background-image: repeating-linear-gradient(-45deg,
                        rgba(255,0,0,.25),
                        rgba(255,0,0,.25) 5px,
                        rgba(0,0,255,.25) 5px,
                        rgba(0,0,255,.25) 10px
                      );
  }
  .text-align-vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
